import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
 
  {
    path: '/',
    name: 'front',
    component: () => import('../views/system/front/admin.vue'),
    redirect: '/home',
  
    children: [
      { path: 'home', name: 'home', meta: { title: '网站首页' }, component: () => import('@/views/system/front/Home') },
      { path: 'dong-honey', name: 'home', meta: { title: '批阅系统' }, component: () => import('@/views/system/front/dong-honey') },
      { path: 'dong-teacher', name: 'home', meta: { title: '组卷系统' }, component: () => import('@/views/system/front/dong-teacher') },
      { path: 'dong-ability', name: 'home', meta: { title: '学校合作' }, component: () => import('@/views/system/front/dong-ability') },
      { path: 'dong-student', name: 'home', meta: { title: '题库系统' }, component: () => import('@/views/system/front/dong-student') },
      { path: 'grade', name: 'home', meta: { title: '成绩系统' }, component: () => import('@/views/system/front/grade') },
      { path: 'dong-us', name: 'home', meta: { title: '关于我们' }, component: () => import('@/views/system/front/dong-us') },
      { path: 'dong-cooperation', name: 'home', meta: { title: '联系我们' }, component: () => import('@/views/system/front/dong-cooperation') },
      { path: 'app', name: 'home', meta: { title: 'App下载' }, component: () => import('@/views/system/front/App') },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async(to, form, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
      next()
  } else {
      document.title = '小乖教育' //此处写默认的title
  }
  
  //接下来写正常的路由守卫就可以了
})


export default router
